import { ReactElement } from 'react';

import ProjectDOM from "./ProjectDOM";

export type Project = {
    readonly title: string,
    description: string,
    languages: string[],
    hasVisitLink: boolean,
    hasGithubLink: boolean,
    visitLink?: string,
    githubLink?: string,
}

export default function Projects(): ReactElement {
    const projects:Project[] = [
         {
            title: "iytepaketi.net",
            description: "this was my hobby project and i decided to publish it online and make deals with local restaurants near iyte. this project primarily focuses on user and restaurant experience and make people's life easier. this project have some unique features that differ from their competitors, such as fully enhanced admin panel, automated ordering system, image uploading, improved security...",
            languages: ["React", "Spring Boot", "MySQL", "Java"],
            hasVisitLink: true,
            hasGithubLink: false,
            visitLink: "https://iytepaketi.net",
        },
        {
            title: "Student Result Management System",
            description: "The Student-Result project organizes and visualizes academic performance by storing exam rankings and average scores for each lecture. It features a home screen ranking students by total scores, and individual pages for each exam and lecture with detailed rankings and bar charts. Users can also select different semesters to view and compare data.",
            languages: ["React", "Flask", "MySQL", "Python"],
            hasVisitLink: true,
            hasGithubLink: false,
            visitLink: "https://iztechcs-results.netlify.app",
        },
        {
            title: "Movie Night (Not online)",
            description: "The Movie Night app is designed to make group movie selection fun and effortless. With features like genre selection, customizable filters, and a voting system, it ensures everyone gets a say in what to watch. The app includes a session lobby for easy coordination and a ranking screen to see top picks.",
            languages: ["React", "Flask", "MySQL", "Python"],
            hasVisitLink: true,
            hasGithubLink: false,
            visitLink: "",
        },
        {
            title: "IYTE Ulasim",
            description: "Public transportation times helper for students at iztech.",
            languages: ["JavaScript"],
            hasGithubLink: true,
            hasVisitLink: true,
            visitLink: "https://iyteulasim.com",
            githubLink: 'https://github.com/erdemylmaz/iyte-ulasim'
        },
        {
            title: "Path Finding Algorithm Visualization",
            description: "Path finding algorithm using heuristics visualization using JS canvas.",
            languages: ["JavaScript"],
            hasGithubLink: true,
            hasVisitLink: true,
            visitLink: "https://erdemylmaz.github.io/path-finding/",
            githubLink: "https://github.com/erdemylmaz/path-finding",
        },
        {
            title: "Eight Puzzle Algorithm Visualization",
            description: "Eight puzzle solver algorithm visualization.",
            languages: ["JavaScript"],
            hasGithubLink: true,
            hasVisitLink: true,
            visitLink: "https://erdemylmaz.github.io/eight-puzzle-visualized/",
            githubLink: "https://github.com/erdemylmaz/eight-puzzle-visualized",
        },
    ];

    return (
        <div className="projects-area">
            <div className="projects-text">Projects</div>
            <div className='projects'>
                {projects.map((project:Project) => {
                    return <ProjectDOM project={project} />
                })}
            </div>
            <a href="https://github.com/erdemylmaz" target="_blank" className="projects-and-more-text">and more...</a>
        </div>
    )
}
